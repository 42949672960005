import AnimatedNumber from 'react-animated-number';

const formatNumber = (value) => {
  return Number(value).toLocaleString();
}



const CardsWidget20 = ({ className, description, color, img, head_value, bot_text, icon, percentage,bot_value }) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div style={{color: 'black !important'}} className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 counter ng-binding'>
          <AnimatedNumber  formatValue={formatNumber} component="text" value={Number(head_value)}
            default={0}
            style={{
              transition: '1 s ease-out',
              fontSize: 22,
              transitionProperty: 'background-color, color, opacity',
            }}
            // formatValue={n => n.toFixed(0)}
            frameStyle={percentage => percentage > 0 && percentage < 100 ? { opacity: 0.5 } : {}}
            duration={550}
          />
        </span>
        <span className='text-white opacity-75 pt-1 fw-semibold fs-2'>{description}</span>
      </div>
      <div className='icon'>
        <i style={{fontSize: '2rem', color: '#f7f7f7'}} className={icon}></i>
      </div>
    </div>
    <div className='card-body d-flex align-items-end pt-0'>
      <div className='d-flex align-items-center flex-column mt-3 w-100'>
        {/* <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
          <div
            className='bg-white rounded h-8px'
            role='progressbar'
            style={{ width: percentage + "%" }}
            aria-valuenow={10}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>

          <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>{bot_text}</span>
            <span>{bot_value}</span>
          
          </div>
        </div> */}
      </div>
    </div>
  </div>
);
export {CardsWidget20};
