import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/dashboard`;

const getNhacViecDeTai = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/NhacViecDeTai`,
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const getWidget = async (year = '', month = '') => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/Widget?year=${year}&month=${month}`,
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const getChartDeTaiTheoTheLoai = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/ChartTacPhamTheoTheLoai`, //TODO: fix here
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const getChartDeTaiTheoPhongBan = async (year = '', month = '') => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/ChartTacPhamTheoPhongBan?year=${year}&month=${month}`,
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

export const dashboardApi = {
  getNhacViecDeTai,
  getWidget,
  getChartDeTaiTheoTheLoai,
  getChartDeTaiTheoPhongBan,
};
