function getObjectPropertyValueByKey(obj: any, key: string): any {
  const map = new Map(Object.entries(obj))
  if (obj.hasOwnProperty(key) && map) {
    return map.get(key)
  }
}

/**
 * Generates unique ID for give prefix.
 * @param {string} prefix Prefix for generated ID
 * @returns {boolean}
 */
function getUniqueIdWithPrefix(prefix: string | undefined): string {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  const result = array[0].toString();
  if (!prefix) {
    return result
  }

  return `${prefix}${result}`
}

/* eslint-disable no-useless-escape */
function stringSnakeToCamel(str: string): string {
  return str.replace(/(\-\w)/g, function (m) {
    return m[1].toUpperCase()
  })
}

function toJSON(value: string | JSON): JSON | undefined {
  if (typeof value !== 'string') {
    return value
  }

  if (!value) {
    return undefined
  }

  // ("'" => "\"");
  const result = value
    .toString()
    .split('')
    .map((el) => (el !== "'" ? el : '"'))
    .join('')

  const jsonStr = result.replace(/(\w{1,100}:)|(\w{1,100} :)/g, function (matched) {
    return '"' + matched.substring(0, matched.length - 1) + '":'
  })
  
  try {
    return JSON.parse(jsonStr)
  } catch {
    return undefined
  }
}

export {getObjectPropertyValueByKey, getUniqueIdWithPrefix, stringSnakeToCamel, toJSON}
