import {Card, Col, Form, Row, Select} from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {dashboardApi} from 'src/app/apis/dashboard';
import {useAuth} from 'src/app/modules/auth';
import {requestGET} from 'src/utils/baseAPI';
import {checkRole, range} from 'src/utils/utils';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {PageTitle} from '../../../_metronic/layout/core';
import {CardsWidget20} from '../../../_metronic/partials/widgets';

const DashboardWrapper = () => {
  const {currentPermissions} = useAuth();

  const [notifys, setNotifys] = useState([]);
  const [widgets, setWidgets] = useState(null);
  const [options, setOptions] = useState();
  const [dataThietBi, setDataThietBi] = useState(null);
  const [dataDGCB, setDataDGCB] = useState(null);
  const [nhacViecDeTai, setNhacViecDeTai] = useState({});
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchChartDeTaiTheoTheLoai = async () => {
      const res = await dashboardApi.getChartDeTaiTheoPhongBan(year, month);

      setOptions({
        chart: {
          type: 'column',
          height: 300,
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: null, // Xóa tiêu đề của biểu đồ
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: 'Số lượng đề tài',
          },
        },
        legend: {
          enabled: false,
        },
        credits: {enabled: false},
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              // format: '{point.y:.1f}%'
            },
            pointWidth: 30,
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:5px">{series.name}</span><br>',
          // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
          {
            name: 'Số lượng',
            colorByPoint: true,
            data: (res?.data ?? []).map((item) => ({
              name: item?.organizationUnitName ?? '',
              y: item?.soLuong,
              drilldown: item?.organizationUnitName ?? '',
            })),
          },
        ],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right',
            },
          },
          series: [],
        },
      });
    };

    fetchChartDeTaiTheoTheLoai();
  }, [month, year]);

  useEffect(() => {
    const fetchWidget = async () => {
      const res = await dashboardApi.getWidget(year, month);
      setWidgets(res?.data);
    };

    fetchWidget();
  }, [month, year]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await requestGET(`api/v1/dashboard`);
      if (res) {
        setDataThietBi({
          chart: {
            type: 'column',
            height: '300px',
          },
          title: {
            text: '',
            align: 'left',
          },
          xAxis: {
            categories: res.chartThietBi.categories,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Thiết bị',
            },
            stackLabels: {
              enabled: true,
            },
          },
          legend: {
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 70,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: res.chartThietBi.chartSeries,
        });
        setDataDGCB({
          chart: {
            type: 'pie',
            height: '300px',
          },
          title: {
            text: '',
            align: 'left',
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                connectorColor: 'silver',
              },
            },
          },
          series: res.chartDanhGiaCanBo.chartSeries,
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await requestGET(`api/v1/dashboard/notifycation`);
      if (Array.isArray(res)) {
        setNotifys(res);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await dashboardApi.getNhacViecDeTai();

      if (res && res.data) {
        setNhacViecDeTai(res.data);
      }
    };

    fetchData();
  }, []);

  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <div className='row'>
        <div className='col-8'></div>
        <div className='col-2'>
          <Form.Item label='Chọn tháng'>
            <Select value={month} onChange={(value) => setMonth(value)}>
              {range(1, 12).map((month) => (
                <Select.Option key={month} value={month + ''}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='col-2'>
          <Form.Item label='Chọn năm'>
            <Select value={year} onChange={(value) => setYear(value)}>
              {range(2014, 2050).map((year) => (
                <Select.Option key={year} value={year + ''}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className='row gy-5 mb-5 mb-xl-3'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className='h-md-5'
            description='Tổng chương trình đề tài'
            color='#2eb6bd'
            head_value={widgets?.tongChuongTrinhDeTai ?? ''}
            icon='fa fa-user'
            img={toAbsoluteUrl('/media/patterns/vector-12.png')}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className='h-md-5'
            description='Đối tượng hưởng thù lao'
            color='#4198d7'
            head_value={widgets?.doiTuongHuongThuLao ?? ''}
            icon='fa fa-users'
            img={toAbsoluteUrl('/media/patterns/vector-12.png')}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className='h-md-5'
            description='Đối tượng hưởng nhuận bút'
            color='#72c5e5'
            head_value={widgets?.doiTuongHuongNhuanBut ?? ''}
            icon='fa fa-users'
            img={toAbsoluteUrl('/media/patterns/vector-12.png')}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
          <CardsWidget20
            className='h-md-5'
            description='Tổng thù lao đơn vị'
            color='#ffb3b3'
            head_value={widgets?.tongThuLaoDonVi ?? ''}
            icon='fa fa-users'
            img={toAbsoluteUrl('/media/patterns/vector-12.png')}
          />
        </div>
      </div>

      <Row gutter={10} style={{marginBottom: '10px'}}>
        <Col span={8}>
          <Card title='Danh sách công việc' style={{height: '400px', overflow: 'scroll'}} className={'card card-flush undefined'}>
            {notifys?.map((item) => (
              <div>
                <div className='d-flex flex-stack'>
                  <div className='text-gray-700 fw-semibold fs-6 me-2'>{item.title}</div>
                  <div className='d-flex align-items-senter'>
                    <span className='badge badge-circle badge-danger'>{item.value}</span>
                  </div>
                </div>
                <div className='separator separator-dashed my-3'></div>
              </div>
            ))}
            {checkRole(currentPermissions, ['Permissions.DeTai.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-tai-cho-thong-qua'>
                  <RemindItem text='Ý tưởng kịch bản chờ thông qua' number={nhacViecDeTai.deTaiChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.DeTai.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-tai-cho-duyet'>
                  <RemindItem text='Ý tưởng kịch bản chờ duyệt' number={nhacViecDeTai.deTaiChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.DeCuong.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-cuong-cho-thong-qua'>
                  <RemindItem text='Đề cương chờ thông qua' number={nhacViecDeTai.deCuongChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.DeCuong.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-cuong-cho-duyet'>
                  <RemindItem text='Đề cương chờ duyệt' number={nhacViecDeTai.deCuongChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.KichBan.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/kich-ban-cho-thong-qua'>
                  <RemindItem text='Kịch bản chờ thông qua' number={nhacViecDeTai.kichBanChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.KichBan.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/kich-ban-cho-duyet'>
                  <RemindItem text='Kịch bản chờ duyệt' number={nhacViecDeTai.kichBanChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/khsx-cho-thong-qua'>
                  <RemindItem text='Kế hoạch sản xuất chờ thông qua' number={nhacViecDeTai.keHoachSanXuatChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/khsx-cho-duyet'>
                  <RemindItem text='Kế hoạch sản xuất chờ duyệt' number={nhacViecDeTai.keHoachSanXuatChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.TacPham.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/thong-qua-tac-pham'>
                  <RemindItem text='Tác phẩm chờ thông qua' number={nhacViecDeTai.tacPhamChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.TacPham.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/duyet-tac-pham'>
                  <RemindItem text='Tác phẩm chờ duyệt' number={nhacViecDeTai.tacPhamChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/thong-qua-xep-loai'>
                  <RemindItem text='Xếp loại tác phẩm chờ thông qua' number={nhacViecDeTai.xepLoaiTacPhamChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/duyet-xep-loai'>
                  <RemindItem text='Xếp loại tác phẩm chờ duyệt' number={nhacViecDeTai.xepLoaiTacPhamChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
          </Card>
        </Col>
        <Col span={16}>
          <Card title='Thống kê đề tài theo phòng ban' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Card>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Card title='Tỷ lệ sử dụng thiết bị' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={dataThietBi} oneToOne={true} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title='Đánh giá cán bộ trong kỳ' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={dataDGCB} oneToOne={true} />
          </Card>
        </Col>
      </Row>
      <div className='row gy-5 mb-5 mb-xl-10'>
        {/* begin::Col */}

        {/* <div className='col-12 pt-1'>
          <div className={`card mt-5`}>
            <div className='card-header ribbon ribbon-top ribbon-vertical px-3'>
              <div className='card-title text-primary'>
                <span className='fa fa-chart-bar me-3 text-primary'></span> Biểu đồ thống kê Đài truyền hình
              </div>
            </div>
            <div className='card-body pb-0 px-4'>
            </div>
          </div>
        </div>*/}
      </div>
    </>
  );
};

const RemindItem = ({text, number}) => {
  return (
    <div class='d-flex flex-stack'>
      <div class='text-gray-700 fw-semibold fs-6 me-2'>{text}</div>
      <div class='d-flex align-items-senter'>
        <span className='badge badge-circle badge-danger'>{number}</span>
      </div>
    </div>
  );
};

export {DashboardWrapper};
